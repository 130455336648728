const attributes = { title: 'title' };

export default class HtmlLessonContentItem extends HTMLElement {
  // The customizable h3
  #titleElement;

  static get observedAttributes() {
    return Object.values(attributes);
  }

  attributeChangedCallback(name, oldVal, newVal) {
    if (oldVal === newVal) return;

    if (name === attributes.title) {
      this.updateTitle(oldVal, newVal);
    }
  }

  createTitle() {
    this.#titleElement = document.createElement('h3');
    this.#titleElement.classList.add('sjwc-section-title');
    this.#titleElement.innerText = this.getAttribute(attributes.title);

    this.prepend(this.#titleElement);
  }

  updateTitle(oldTitle, newTitle) {
    if (newTitle === oldTitle) return;

    if (this.#titleElement) {
      if (!newTitle) {
        this.#titleElement.remove();
        this.#titleElement = null;
        return;
      }

      this.#titleElement.innerText = newTitle;
    }

    if (newTitle && !this.#titleElement) {
      this.createTitle();
    }
  }
}

customElements.define('sjwc-lesson-content-item', HtmlLessonContentItem);
