<template>{{ showMonthString ? toMonthCopy: toDayCopy }}</template>

<script setup lang="ts">
import { computed } from 'vue';
import HelperService from '@utils/helper-service';

const props = defineProps({
  showMonthString: {
    type: Boolean,
    default: false,
  },
  showDayString: {
    type: Boolean,
    default: false,
  },
  dateTime: {
    type: String,
    required: true,
  },
});

const preSelectedTimezone = localStorage.getItem('sjcalendar_selected_timezone');
const locale = HelperService.getLanguageCode();

// Prioritize using the timezone from the picker in the /calendar page over the browser's timezone.
const selectedTimezone = preSelectedTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

const toMonthCopy = computed(() => {
  if (props.showMonthString) {
    return new Date(props.dateTime).toLocaleString(locale, { timeZone: selectedTimezone, month: "short"});
  }
  return '';
});

const toDayCopy = computed(() => {
  if (props.showDayString) {
    // We hardcode the en-US locale for only the day, since we want to render numbers only.
    // (Some languages add prefixes/suffixes in their language after the day)
    return new Date(props.dateTime).toLocaleString("en-US", { timeZone: selectedTimezone, day: "numeric"});
  }
  return '';
});
</script>