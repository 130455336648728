const attributes = { videoId: 'video-id' };

export default class KollectiveEmbed extends HTMLElement {
  #rootElement;

  #videoId;

  static get observedAttributes() {
    return [attributes.videoId];
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);

    this.#rootElement = document.createElement('iframe');
    const baseUrl = this.#videoId;
    const params = {
      repeat: true,
      client: false,
      context: 'embed',
      overlay: false,
      name: false,
      description: false,
      created_by: false,
      created_at: false,
      rating: false,
      sharing: false,
      favorites: false,
      chapters: false,
      tags: false,
      flagging: false,
      views: false,
      length: false,
      downloading: true,
      autoPlay: false,
    };

    const url = new URL(baseUrl);
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const playerFrameAttrs = {
      src: url,
      scrolling: 'no',
      allowfullscreen: 'true',
      style: 'border:none;width:100%;height:100%',
    };

    Object.entries(playerFrameAttrs).forEach(([key, value]) => this.#rootElement.setAttribute(key, value));

    this.appendChild(this.#rootElement);
  }
}

customElements.define('sjwc-kollective', KollectiveEmbed);
