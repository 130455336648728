import vidyardEmbed from '@vidyard/embed-code';

const attributes = { playDisallowed: 'play-disallowed', videoId: 'video-id' };

export default class VidyardPlayer extends HTMLElement {
  #rootElement;

  #placeholderImg;

  #videoId;

  #player;

  static get observedAttributes() {
    return [attributes.videoId, attributes.playDisallowed];
  }

  constructor() {
    super();

    this.#rootElement = document.createElement('div');
    this.#placeholderImg = document.createElement('img');

    this.#placeholderImg.classList.add('vidyard-player-embed');
    this.#placeholderImg.setAttribute('data-v', '4');
    this.#placeholderImg.setAttribute('data-type', 'inline');
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);

    this.#placeholderImg.setAttribute('src', `https://play.vidyard.com/${this.#videoId}.jpg`);
    this.#placeholderImg.setAttribute('data-uuid', this.#videoId);

    this.#rootElement.appendChild(this.#placeholderImg);

    this.appendChild(this.#rootElement);

    vidyardEmbed.api.renderPlayer(this.#placeholderImg);
    vidyardEmbed.api.addReadyListener((_, player) => {
      this.#player = player;
    }, this.#videoId);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === attributes.playDisallowed) {
      if (newValue && this.#player) {
        this.#player.pause();
      }
    }
  }
}

customElements.define('sjwc-vidyard', VidyardPlayer);
