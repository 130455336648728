const attributes = { videoId: 'video-id' };

export default class BrightcovePlayer extends HTMLElement {
  #rootElement;

  #videoId;

  #playerFrame;

  static get observedAttributes() {
    return [attributes.videoId];
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);

    this.#rootElement = document.createElement('div');
    this.#rootElement.classList.add('embed-container');

    this.#playerFrame = document.createElement('iframe');
    const playerFrameAttrs = {
      src: `https://bcove.video/${this.#videoId}`,
      frameborder: '0',
      allowfullscreen: 'true',
    };

    Object.entries(playerFrameAttrs).forEach(([key, value]) => this.#playerFrame.setAttribute(key, value));

    this.#rootElement.appendChild(this.#playerFrame);
    this.appendChild(this.#rootElement);
  }
}

customElements.define('sjwc-brightcove', BrightcovePlayer);
