import HelperService from '@utils/helper-service';

const attributes = {
  videoId: 'video-id',
  class: 'wistia-embed',
  name: 'wistia-embed',
  playerCompleteCallback: 'player-complete-callback',
  playDisallowed: 'play-disallowed',
};

export default class WistiaEmbed extends HTMLElement {
  #rootElement;

  #videoId;

  #playDisallowed;

  #playerCompleteCallback;

  #scriptElement;

  static get observedAttributes() {
    return [attributes.videoId, attributes.playDisallowed];
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);
    this.#playerCompleteCallback = this.getAttribute(attributes.playerCompleteCallback);
    this.#playDisallowed =
      this.hasAttribute(attributes.playDisallowed) && this.getAttribute(attributes.playDisallowed) !== 'false';
    this.#rootElement = document.createElement('div');

    const playerElement = document.createElement('iframe');

    const playerAttributes = {
      src: `//fast.wistia.net/embed/iframe/${this.#videoId}?controlsVisibleOnLoad=false`,
      allowtransparency: 'true',
      frameborder: '0',
      scrolling: 'no',
      class: attributes.class,
      name: attributes.name,
      allowfullscreen: '',
      mozallowfullscreen: '',
      webkitallowfullscreen: '',
      oallowfullscreen: '',
      msallowfullscreen: '',
    };

    Object.keys(playerAttributes).forEach((attr) => playerElement.setAttribute(attr, playerAttributes[attr]));
    this.handleScript();

    this.#rootElement.appendChild(playerElement);
    this.appendChild(this.#rootElement);
  }

  handleScript() {
    const scriptAttributes = {
      src: `//fast.wistia.net/assets/external/E-v1.js`,
    };

    this.#scriptElement = HelperService.loadScript(scriptAttributes, this.#rootElement);
  }
}

customElements.define('sjwc-wistia', WistiaEmbed);
