<template>
  <div class="catalog-tile-wrapper">
    <div class="catalog-tile-wrapper__content">
      <div class="catalog-tile-wrapper__content__header-wrapper">
        <slot name="header" />
        <slot name="subheader" />
      </div>
      <div ref="tileWrapper" class="catalog-tile-wrapper__content__tiles">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, inject, onMounted, ref } from 'vue';
import ApiService from '@utils/services/api-service';
import { AdditionalTileData, AdditionalTileDataItem } from '@utils/types';

const emit = defineEmits(['error']);
const props = defineProps({
  obfuscatedId: {
    type: String,
    default: '',
  },
});

const theme = inject('theme', {});

const tileWrapper = ref<HTMLElement | null>(null);

const propagateTileData = (additionalTileData: AdditionalTileData) => {
  if (!tileWrapper.value) return;

  const $defaultSlot: HTMLSlotElement | null = tileWrapper.value?.querySelector('slot:not([name])');

  const $tiles: Element[] = $defaultSlot?.assignedElements() || [];

  Object.keys(additionalTileData).forEach((catalogItemType) => {
    Object.keys(additionalTileData[catalogItemType]).forEach((tileObfuscatedId) => {
      // find the tile that matches the obfuscated id and type, use both to ensure uniqueness
      const $tile = $tiles.find(($el: Element) => {
        const matchingObfuscatedId = $el.getAttribute('obfuscated-id') === tileObfuscatedId;
        const matchingType = $el.getAttribute('type') === catalogItemType;

        return matchingObfuscatedId && matchingType;
      });

      const matchingTileData: AdditionalTileDataItem = additionalTileData[catalogItemType][tileObfuscatedId];

      // return if theres no matching tile or tile
      if (!$tile || !matchingTileData) return;

      // set attributes on tile
      Object.keys(matchingTileData).forEach((key) => {
        const attributeValue = matchingTileData[key];

        // if there are object keys that need to be attribute-compatible, add them to this object
        const attributeNameMap: { [key: string]: string } = {
          included_in_user_plan: 'included-in-plan',
          contained_items_count: 'contained-items-count',
        };

        const attributeKey = attributeNameMap[key] || key;

        // set the attribute on the tile if it exists
        if (attributeValue === true) {
          $tile?.setAttribute(attributeKey, '');
        } else if (attributeValue === false) {
          return;
        } else {
          $tile?.setAttribute(attributeKey, attributeValue);
        }
      });
    });
  });
};

onMounted(async () => {
  if (!props.obfuscatedId) return;

  try {
    const response = await ApiService.getAdditionalTileData(props.obfuscatedId);

    propagateTileData(response.data);
  } catch (ex) {
    emit('error', ex);
  }
});
</script>

<style lang="scss" scoped>
.catalog-tile-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  &__content {
    width: 100%;

    @media only screen and (min-width: 545px) {
      width: 528px;
    }

    @media only screen and (min-width: 607px) {
      width: 590px;
    }

    @media only screen and (min-width: 801px) {
      width: 784px;
    }

    @media only screen and (min-width: 926px) {
      width: 909px;
      padding: 0 24px;
    }

    @media only screen and (min-width: 1213px) {
      width: 1196px;
    }

    @media only screen and (min-width: 1500px) {
      width: 1483px;
    }

    &__header-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &__tiles {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      ::slotted(sjwc-catalog-tile) {
        width: 280px;
        margin: 0.5rem;

        @media only screen and (max-width: 1151px) {
          width: 244px;
        }
      }
    }
  }
}
</style>

<style scoped>
.catalog-tile-wrapper {
  font-family: v-bind(theme.fontFamily);
}

.catalog-tile-wrapper__content__header-wrapper ::slotted(h2) {
  margin: 0;
  margin-top: 1.5rem;
  padding: 0;
  font-size: v-bind(theme.h2FontSize);
  font-weight: v-bind(theme.h2FontWeight);
  color: v-bind(theme.h2Color);
  line-height: 1.3;
}

.catalog-tile-wrapper__content__header-wrapper ::slotted(p) {
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  font-size: v-bind(theme.paragraphFontSize);
  font-weight: v-bind(theme.paragraphFontWeight);
  color: v-bind(theme.paragraphColor);
  line-height: 1.5;
}
</style>
