import { v4 as uuidv4 } from 'uuid';
import HelperService from '@utils/helper-service';

const attributes = {
  videoId: 'video-id',
  playDisallowed: 'play-disallowed',
};

export default class YouTubeEmbed extends HTMLElement {
  #rootElement;

  #playerElement;

  #scriptElement;

  #videoId;

  #playerId;

  #youtubeInstance;

  #playDisallowed;

  static get observedAttributes() {
    return Object.values(attributes);
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);
    this.#playerId = `yt_${this.#videoId}_${uuidv4()}`;
    this.#playDisallowed =
      this.hasAttribute(attributes.playDisallowed) && this.getAttribute(attributes.playDisallowed) !== 'false';

    this.#rootElement = document.createElement('div');
    this.#rootElement.classList.add('embed-container');

    this.#playerElement = document.createElement('div');

    const youtubePlayerAttributes = {
      width: '768',
      height: '453',
      frameborder: '0',
      allowfullscreen: '',
      id: this.#playerId,
      class: 'yt-player',
    };

    Object.entries(youtubePlayerAttributes).forEach(([key, value]) => this.#playerElement.setAttribute(key, value));

    this.handleScript();

    this.#rootElement.appendChild(this.#playerElement);
    this.appendChild(this.#rootElement);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === attributes.playDisallowed && !!this.#youtubeInstance) {
      this.handlePlayDisallowed(newValue);
    }
  }

  handleScript() {
    const scriptAttributes = {
      type: 'text/javascript',
      src: `https://www.youtube.com/iframe_api`,
      async: true,
    };

    this.#scriptElement = HelperService.loadScript(
      scriptAttributes,
      this.#rootElement,
      this.instantiateInstance.bind(this)
    );
  }

  instantiateInstance() {
    const onReady = () => {
      this.#youtubeInstance = new YT.Player(this.#playerId, {
        videoId: this.#videoId,
        playerVars: {
          rel: 0,
        },
      });

      this.handlePlayDisallowed();
    };

    YT.ready(onReady);
  }

  handlePlayDisallowed(newValue) {
    if (!this.#youtubeInstance) return;

    this.#playDisallowed = newValue === 'true';

    const activePlayerStates = [0, 1, 3];
    const playerState = this.#youtubeInstance.getPlayerState();
    const playerIsActive = activePlayerStates.includes(playerState);
    const playingWhenNotAllowed = playerIsActive && this.#playDisallowed;

    this.classList.toggle('pe-none', this.#playDisallowed || false);

    if (playingWhenNotAllowed) {
      this.#youtubeInstance.pauseVideo();
    }
  }
}

customElements.define('sjwc-youtube', YouTubeEmbed);
