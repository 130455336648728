<template>
  <span
    v-if="!showDateOnly"
    v-html="formattedTime"
  />
  <span v-else>
    {{ formattedTime }}
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import sanitize from 'sanitize-html';
import HelperService from '@utils/helper-service';

const props = defineProps({
  translateKey: {
    type: String,
    default: '',
  },
  translateParam: {
    type: String,
    default: '',
  },
  utcTimestamp: {
    type: String,
    required: true,
  },
  showDateOnly: {
    type: Boolean,
    default: false,
  },
});

const locale = HelperService.getLanguageCode();

const convertedDate = computed(() => {
  const preSelectedTimezone = localStorage.getItem('sjcalendar_selected_timezone');
  // Prioritize using the timezone from the picker in the /calendar page over the browser's timezone.
  const selectedTimezone = preSelectedTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  let dateString;

  if (props.showDateOnly) {
    dateString = new Date(props.utcTimestamp).toLocaleString(locale, {
      timeZone: selectedTimezone,
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'shortGeneric',
      hour12: true,
    });
  } else {
    dateString = new Date(props.utcTimestamp).toLocaleString(locale, {
      timeZone: selectedTimezone,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'shortGeneric',
      hour12: true,
    });
  }
  return dateString;
});

const formattedTime = computed(() => {
  if (!props.showDateOnly) {
    // timezone_name is already provided within `convertedDate.value`, so give it an empty string
    return sanitize(HelperService.translate(props.translateKey, { [props.translateParam]: convertedDate.value, timezone_name: '' }));
  }
  return convertedDate.value;
});
</script>
