import { v4 as uuidv4 } from 'uuid';
import HelperService from '@utils/helper-service';

const attributes = { playDisallowed: 'play-disallowed', videoId: 'video-id' };

// here's an example of a videoId:
// https://cdnapisec.kaltura.com/p/5200712/sp/5200712100/embedIframeJs/uiconf_id/52128882/partner_id/5200712?autoembed=true&entry_id=1_erhh7l3b&playerId=kaltura_player_1677181181&cache_st=1677181181&width=700&height=393
const extractOptionsFromVideoId = (videoId) => {
  const options = {};

  const parts = videoId.split('/');

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part === 'partner_id') {
      options.wid = `_${parts[++i].split('?')[0]}`;
    } else if (part === 'uiconf_id') {
      options.uiconf_id = parts[++i];
    }
  }

  const params = new URLSearchParams(videoId);

  if (params.get('entry_id')) {
    options.entry_id = params.get('entry_id');
  }

  if (params.get('playerId')) {
    options.targetId = params.get('playerId');
  }

  const width = params.get('width') || '400';
  const height = params.get('height') || '330';
  const aspectRatio = width / height;

  return { options, width, height, aspectRatio };
};

export default class KalturaPlayer extends HTMLElement {
  #rootElement;

  #scriptElement;

  #player;

  #videoId;

  #videoContainer;

  #videoContainerId;

  static get observedAttributes() {
    return [attributes.videoId, attributes.playDisallowed];
  }

  constructor() {
    super();

    this.#rootElement = document.createElement('div');
    this.#rootElement.classList.add('sjwc-kaltura-root');
    this.#videoContainer = document.createElement('div');

    this.#videoContainerId = uuidv4();
    this.#videoContainer.setAttribute('id', this.#videoContainerId);
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);

    this.#scriptElement = HelperService.loadScript(
      {
        src: this.#videoId.split('?'),
      },
      this.#rootElement,
      this.initializePlayer.bind(this)
    );

    this.#rootElement.appendChild(this.#videoContainer);

    this.appendChild(this.#rootElement);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === attributes.playDisallowed) {
      if (newValue && this.#player) {
        this.#player.sendNotification('doPause');
      }
    }
  }

  initializePlayer() {
    const { options } = extractOptionsFromVideoId(this.#videoId);

    // DBW 3/23/2023: these numbers are magical :sparkles:
    this.#rootElement.style.height = `80cqh`;
    this.#rootElement.style.maxHeight = `${window.innerHeight}px`;
    this.#videoContainer.style.height = `80cqh`;
    this.#videoContainer.style.maxHeight = `${window.innerHeight}px`;

    options.targetId = this.#videoContainerId;
    options.readyPlayback = (playerId) => {
      this.#player = document.getElementById(playerId);
    };

    kWidget.embed(options);
  }
}

customElements.define('sjwc-kaltura', KalturaPlayer);
