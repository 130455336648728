import HelperService from '@utils/helper-service';

export default class Alert extends HTMLElement {
  static CONTEXT_TYPE = 'alert-service';

  static VALID_TYPES = ['success', 'warning', 'error', 'info'];

  constructor() {
    super();
    document.body.addEventListener('context-request', (event) => {
      // Return if this is a request for a different context
      if (event.detail.type !== Alert.CONTEXT_TYPE) {
        return;
      }
      // If we get here then we can handle the request so stop the propagation
      event.stopPropagation();

      event.detail.callback({
        showAlert: this.handleShowAlert.bind(this),
      });
    });
  }

  handleShowAlert({ element, alertType }) {
    // We can't use sjwc-icon here because we need to match the dom structure of other CP alerts
    const closeIcon = HelperService.createElement('a', {
      class: 'close',
      href: '#',
      innerHTML: '&times;',
    });

    // By default add the closing class so that the alert is not shown
    const alert = HelperService.createElement('div', { class: `alert-box radius ${alertType}` }, [element, closeIcon]);
    alert.setAttribute('data-alert', '');

    // If it's the first alert being added it should have an extra class identifying it
    if (this.childElementCount === 0) {
      alert.classList.add('top-row-message');
    }

    this.appendChild(alert);
  }
}

HelperService.safeDefineCustomElement('sjwc-alert', Alert);
