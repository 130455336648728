export const DEFAULT_WIDESCREEN_ASPECT_RATIO = '1.7777777777777777';

export const DEFAULT_COLLAPSIBLE_SECTION_TOGGLE_COPY = {
  DEFAULT: 'Show More',
  EXPANDED: 'Show Less',
};

// to match domain.catalog_tiles.constants
export const CATALOG_ITEM_TYPES = {
  PATHS: 'PATH',
  PLANS: 'PLAN',
  PUBLISHED_COURSES: 'COURSE',
  CATALOG_PAGES: 'PAGE',
};

export const RIBBON_TYPES = {
  REGISTERED: 'REGISTERED',
  COMPLETED: 'COMPLETED',
  ACTIVE: 'ACTIVE',
  GET_STARTED: 'GET_STARTED',
}

export const CALLOUT_TYPES = {
  TEXT: 'TEXT',
  TIME: 'TIME',
  ITEM_COUNT: 'ITEM_COUNT',
};

// to match BE constants in ThemeModularTileStyle
export const TILE_STYLE = {
  DROP_SHADOW: 'DROP_SHADOW',
  NO_IMAGE: 'NO_IMAGE',
  GRAY_OUTLINE_WITH_LINE_BREAK: 'GRAY_OUTLINE_WITH_LINE_BREAK',
  GRAY_OUTLINE_HIDE_SHORT_DESC: 'GRAY_OUTLINE_HIDE_SHORT_DESC',
  COLOR_OUTLINE: 'COLOR_OUTLINE',
};
