import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_COLLAPSIBLE_SECTION_TOGGLE_COPY } from '@utils/constants';
import HelperService from '@utils/helper-service';

const { createElement } = HelperService;

const attributes = {
  sectionId: 'section-id',
  title: 'title',
  expandedTitle: 'expanded-title',
  checked: 'checked',
};

const events = {
  toggle: 'toggle',
};

export default class CollapsibleSectionTrigger extends HTMLElement {
  #rootElement;

  #sectionId;

  #inputId;

  #input;

  #iconWrapper;

  #title;

  #titleElement;

  #expandedTitle;

  #checked;

  static get observedAttributes() {
    return Object.values(attributes);
  }

  constructor() {
    super();

    this.#sectionId = this.getAttribute(attributes.sectionId) || uuidv4();
    this.#inputId = `checked-${this.#sectionId}`;
    this.#checked = this.hasAttribute(attributes.checked) && this.getAttribute(attributes.checked) !== 'false';
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this.#rootElement || !this.#rootElement.isConnected) return;

    if (name === attributes.checked) this.handleToggle(newValue);

    if (name === attributes.title) this.#titleElement.textContent = this.getToggledTitle();

    if (name === attributes.expandedTitle) this.#titleElement.textContent = this.getToggledTitle();
  }

  connectedCallback() {
    this.#rootElement = this.renderCollapsibleSectionTrigger();
    this.appendChild(this.#rootElement);

    this.#input.addEventListener('change', this.handleInputChange.bind(this));
  }

  disconnectedCallback() {
    this.#input.removeEventListener('change', this.handleInputChange.bind(this));

    this.removeChild(this.#rootElement);
  }

  handleInputChange(e) {
    this.handleToggle(e.target.checked);
    this.emitToggleEvent();
  }

  emitToggleEvent() {
    const event = new CustomEvent(events.toggle, {
      bubbles: true,
      detail: {
        sectionId: this.#sectionId,
        checked: this.#input.checked,
      },
    });

    this.dispatchEvent(event);
  }

  renderCollapsibleSectionTrigger() {
    this.#titleElement = createElement('span', {
      id: `title-${this.#sectionId}`,
      textContent: this.getToggledTitle(),
    });

    const $titleWrapper = createElement(
      'p',
      {
        class: `checked-${this.#sectionId}`,
      },
      [this.#titleElement]
    );

    const $expandIcon = createElement('span', {
      class: 'inline-collapsible-section__label__icons__expand',
    });

    const $collapseIcon = createElement('span', {
      class: 'inline-collapsible-section__label__icons__collapse',
    });

    this.#iconWrapper = createElement(
      'div',
      {
        class: 'inline-collapsible-section__label__icons',
        id: `plus-${this.#sectionId}`,
        tabindex: 0,
      },
      [$expandIcon, $collapseIcon]
    );

    const $label = createElement(
      'label',
      {
        class: 'inline-collapsible-section__label',
        for: `checked-${this.#sectionId}`,
      },
      [this.#iconWrapper, $titleWrapper]
    );

    const inputAttrs = {
      id: `${this.#inputId}`,
      class: 'inline-collapsible-section__toggle',
      type: 'checkbox',
    };

    if (this.#checked) {
      inputAttrs.checked = true;
    }

    this.#input = createElement('input', inputAttrs);

    const $rootElement = createElement(
      'div',
      {
        class: 'inline-collapsible-section',
        id: `inline-collapsible-section-${this.#sectionId}`,
      },
      [this.#input, $label]
    );

    return $rootElement;
  }

  handleToggle(newValue) {
    const falseValues = ['false', 'null', null, false];

    this.#checked = !falseValues.includes(newValue);

    this.#input.toggleAttribute(attributes.checked, this.#checked);
    this.#input.checked = this.#checked;
    this.toggleAttribute(attributes.checked, this.#checked);

    this.#titleElement.textContent = this.getToggledTitle();
  }

  getToggledTitle() {
    this.#title = this.getAttribute(attributes.title) || DEFAULT_COLLAPSIBLE_SECTION_TOGGLE_COPY.DEFAULT;
    this.#expandedTitle =
      this.getAttribute(attributes.expandedTitle) || DEFAULT_COLLAPSIBLE_SECTION_TOGGLE_COPY.EXPANDED;

    return this.#checked ? this.#expandedTitle : this.#title;
  }
}

customElements.define('sjwc-collapsible-section-trigger', CollapsibleSectionTrigger);
