const attributes = {
  name: 'name',
};

export default class Icon extends HTMLElement {
  #rootElement;

  static get observedAttributes() {
    return Object.values(attributes);
  }

  constructor() {
    super();

    this.#rootElement = document.createElement('i');

    const name = this.getAttribute(attributes.name);
    if (name) {
      this.#rootElement.classList.add(...name.split(' '));
    }
  }

  connectedCallback() {
    this.updateName(this.getAttribute(attributes.name));
    this.appendChild(this.#rootElement);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === attributes.name) {
      this.updateName(newValue, oldValue);
    }
  }

  updateName(newValue, oldValue) {
    if (oldValue) {
      this.#rootElement.classList.remove(...oldValue.split(' '));
    }
    this.#rootElement.classList.add(...newValue.split(' '));
  }
}

customElements.define('sjwc-icon', Icon);
