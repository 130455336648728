const attributes = {
  videoId: 'video-id',
};

export default class SynthesiaEmbed extends HTMLElement {
  #rootElement;

  #videoId;

  static get observedAttributes() {
    return [attributes.videoId];
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);
    this.#rootElement = document.createElement('div');

    const playerElement = document.createElement('iframe');

    playerElement.style.border = 'none';

    const playerAttributes = {
      src: `https://share.synthesia.io/embeds/videos/${this.#videoId}`,
      allow: 'encrypted-media; fullscreen;',
      allowfullscreen: '',
    };

    Object.keys(playerAttributes).forEach((attr) => playerElement.setAttribute(attr, playerAttributes[attr]));

    this.#rootElement.appendChild(playerElement);
    this.appendChild(this.#rootElement);
  }
}

customElements.define('sjwc-synthesia', SynthesiaEmbed);
