import axios from 'axios';

export default class Request {
  static async getHelper<T>(url: string, params: any = {}): Promise<T> {
    return axios.get(url, params).then(
      (success) => Promise.resolve(success as T),
      (error) => {
        if (params?.signal?.aborted) return Promise.reject(error);
        if (error.response) return Promise.reject(error.response);

        return Promise.reject(error);
      }
    );
  }
}
