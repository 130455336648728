<template>
  <a :href="link" class="catalog-tile-wrapper">
    <div
      :class="[
        'catalog-tile',
        {
          'catalog-tile__drop-shadow-style': theme.tileStyle === TILE_STYLE.DROP_SHADOW,
          'catalog-tile__no-image-style': theme.tileStyle === TILE_STYLE.NO_IMAGE,
          'catalog-tile__gray-outline-with-line-break-style':
            theme.tileStyle === TILE_STYLE.GRAY_OUTLINE_WITH_LINE_BREAK,
          'catalog-tile__gray-outline-hide-short-desc-style':
            theme.tileStyle === TILE_STYLE.GRAY_OUTLINE_HIDE_SHORT_DESC,
          'catalog-tile__color-outline-style': theme.tileStyle === TILE_STYLE.COLOR_OUTLINE,
        },
      ]"
    >
      <div v-if="ribbonType" class="catalog-tile__ribbon-wrapper">
        <div :class="['catalog-tile__ribbon-wrapper__ribbon', `catalog-tile__ribbon-wrapper__${ribbonTypeClass}`]">
          {{ ribbonText }}
        </div>
      </div>
      <slot name="image" v-if="theme.tileStyle !== TILE_STYLE.NO_IMAGE">
        <div v-if="imageUrl" class="catalog-tile__img-wrapper">
          <img :src="imageUrl" loading="lazy" />
        </div>
      </slot>
      <div class="catalog-tile__content">
        <slot name="title">
          <h2 v-if="title" class="catalog-tile__content__title">
            {{ title }}
          </h2>
        </slot>
        <slot name="description" v-if="theme.tileStyle !== TILE_STYLE.GRAY_OUTLINE_HIDE_SHORT_DESC">
          <p class="catalog-tile__content__description">
            {{ description }}
          </p>
        </slot>
        <div class="catalog-tile__content__footer">
          <div class="catalog-tile__content__footer__callouts" v-if="leftCallout || rightCallout">
            <p class="catalog-tile__content__footer__callouts__left">
              {{ leftCallout }}
            </p>
            <p class="catalog-tile__content__footer__callouts__right">
              {{ rightCallout }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
import { CATALOG_ITEM_TYPES, CALLOUT_TYPES, RIBBON_TYPES, TILE_STYLE } from '@utils/constants';
const { PATHS, PLANS, PUBLISHED_COURSES } = CATALOG_ITEM_TYPES;
const { REGISTERED, COMPLETED, ACTIVE, GET_STARTED } = RIBBON_TYPES;
const { TEXT, TIME, ITEM_COUNT } = CALLOUT_TYPES;

const props = defineProps({
  obfuscatedId: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
    validate: (value: string) => Object.values(CATALOG_ITEM_TYPES).includes(value) || value === '',
  },
  activeCopy: {
    type: String,
    default: 'Active',
  },
  completed: {
    type: Boolean,
    default: false,
  },
  completedCopy: {
    type: String,
    default: 'Complete',
  },
  description: {
    type: String,
    default: '',
  },
  getStartedCopy: {
    type: String,
    default: 'Get Started',
  },
  imageUrl: {
    type: String,
    default: '',
  },
  includedInPlan: {
    type: Boolean,
    default: false,
  },
  link: {
    type: String,
    required: true,
  },
  registered: {
    type: Boolean,
    default: false,
  },
  registeredCopy: {
    type: String,
    default: 'Registered',
  },
  title: {
    type: String,
    default: '',
  },
  price: {
    type: String,
    default: '',
  },
  calloutType: {
    type: String,
    default: 'TEXT',
    validate: (value: string) => Object.values(CALLOUT_TYPES).includes(value),
  },
  calloutText: {
    type: String,
    default: '',
  },
  calloutTime: {
    type: String,
    default: '',
  },
  containedItemsCount: {
    type: [String, Number],
    default: null,
  },
  calloutContainedItemsPlural: {
    type: String,
    default: '',
  },
  calloutContainedItemsSingular: {
    type: String,
    default: '',
  },
});

const theme = inject('theme', {});

const ribbonType = computed(() => {
  if (props.completed) return COMPLETED;

  if (props.registered) {
    if (props.type == PLANS) {
      return ACTIVE;
    } else {
      return REGISTERED;
    }
  }

  if (props.includedInPlan) return GET_STARTED;

  return null;
});

const ribbonText = computed(() => {
  switch (ribbonType.value) {
    case REGISTERED:
      return props.registeredCopy;
    case COMPLETED:
      return props.completedCopy;
    case ACTIVE:
      return props.activeCopy;
    case GET_STARTED:
      return props.getStartedCopy;
  }
  return '';
});

const ribbonTypeClass = computed(() => {
  switch (ribbonType.value) {
    case REGISTERED:
      return 'ribbon-registered';
    case COMPLETED:
      return 'ribbon-completed';
    case ACTIVE:
      return 'ribbon-active';
    case GET_STARTED:
      return 'ribbon-get-started';
  }
  return '';
});

const leftCallout = computed(() => {
  if (props.type == PUBLISHED_COURSES || props.type == PATHS || props.type == PLANS) {
    return props.price;
  }
  return '';
});

const rightCallout = computed(() => {
  switch (props.calloutType) {
    case TIME:
      return props.calloutTime;
    case ITEM_COUNT:
      if (props.containedItemsCount !== null) {
        return window.skilljarTranslate('storefront_course_count', {
          course_count: props.containedItemsCount,
          courses_name_plural: props.calloutContainedItemsPlural,
          courses_name_singular: props.calloutContainedItemsSingular,
        });
      }
      break;
    case TEXT:
      return props.calloutText;
    default:
      return '';
  }
});
</script>

<style>
.catalog-tile-wrapper {
  text-decoration: none;
  color: inherit;
}

.catalog-tile-wrapper:focus {
  outline: none;
}

.catalog-tile {
  position: relative;
  overflow: hidden;
  font-family: v-bind(theme.fontFamily);
  border-radius: 4px;
  background: v-bind(theme.tileBackgroundColor);
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.1s ease-in-out, width 0.01ms, height 0.01ms;
  border: v-bind(theme.tileBorderWidth + theme.tileBorderWidthUnit) solid;
  border-color: v-bind(theme.tileBorderColor);
}

.catalog-tile-wrapper:hover .catalog-tile,
.catalog-tile-wrapper:focus .catalog-tile {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.catalog-tile-wrapper:hover .catalog-tile {
  border-color: v-bind(theme.tileHoverBorderColor);
}

.catalog-tile-wrapper:focus .catalog-tile {
  box-shadow: 0 0 0 3px v-bind(theme.tileFocusBoxColor);
  border-color: v-bind(theme.tileFocusBorderColor);

  transition: ease 255ms;
  transition-property: border, box-shadow;
}

.catalog-tile__ribbon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 85px;
  height: 88px;
  overflow: hidden;
}

.catalog-tile__ribbon-wrapper__ribbon {
  box-shadow: none;
  font-size: 11px;
  font-weight: bold;
  left: -5px;
  padding: 6px 0;
  position: relative;
  text-align: center;
  top: 15px;
  transform: rotate(45deg);
  width: 120px;
  border-top: 3px solid;
  border-bottom: 3px solid;
}

.catalog-tile__ribbon-wrapper__ribbon-registered {
  background: v-bind(theme.tileRegisteredRibbonBackgroundColor);
  color: v-bind(theme.tileRegisteredRibbonColor);
  border-color: v-bind(theme.tileRegisteredRibbonOutlineColor);
}

.catalog-tile__ribbon-wrapper__ribbon-completed {
  background: v-bind(theme.tileCompletedRibbonBackgroundColor);
  color: v-bind(theme.tileCompletedRibbonColor);
  border-color: v-bind(theme.tileCompletedRibbonOutlineColor);
}

.catalog-tile__ribbon-wrapper__ribbon-active {
  background: v-bind(theme.tileActiveRibbonBackgroundColor);
  color: v-bind(theme.tileActiveRibbonColor);
  border-color: v-bind(theme.tileActiveRibbonOutlineColor);
}

.catalog-tile__ribbon-wrapper__ribbon-get-started {
  background: v-bind(theme.tileGetStartedRibbonBackgroundColor);
  color: v-bind(theme.tileGetStartedColor);
  border-color: v-bind(theme.tileGetStartedRibbonOutlineColor);
}

.catalog-tile__img-wrapper {
  width: 100%;
  height: auto;
  display: flex;
}

.catalog-tile__img-wrapper img,
.catalog-tile__img-wrapper ::slotted(img) {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.catalog-tile__content {
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.catalog-tile__content__title,
.catalog-tile__content ::slotted(h2) {
  margin: 0;
  padding: 0;
  font-size: v-bind(theme.h2TileTitleFontSize);
  font-weight: v-bind(theme.h2TileTitleFontWeight);
  color: v-bind(theme.h2Color);
  line-height: 1.3;
}

.catalog-tile__content .catalog-tile__content__description,
.catalog-tile__content ::slotted(p) {
  margin: 0;
  padding-top: 18px;
  font-size: v-bind(theme.paragraphFontSize);
  font-weight: v-bind(theme.paragraphFontWeight);
  color: v-bind(theme.paragraphColor);
  line-height: 1.5;
}

.catalog-tile__content__footer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  height: 48px;
  font-size: v-bind(theme.paragraphFontSize);
  font-weight: v-bind(theme.paragraphFontWeight);
}

.catalog-tile__content__footer__callouts {
  display: flex;
  justify-content: space-between;
  color: v-bind(theme.tileCalloutColor);
}

.catalog-tile__content__footer__callouts p {
  margin: 0;
}

/*  tile styles  */
.catalog-tile__drop-shadow-style,
.catalog-tile__no-image-style {
  border-radius: 0.25em;
  border-bottom-width: 0.125em;
  border-bottom-style: solid;
  border-color: transparent;
  box-shadow: 0 0.375em 0.75em 0.0625em rgba(0, 0, 0, 0.1);
}

.catalog-tile__drop-shadow-style:hover,
.catalog-tile__no-image-style:hover {
  box-shadow: 0 0.375em 0.75em 0.125em rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
  border-color: v-bind(theme.tileHoverBorderColor);
  transition: 200ms;
}

.catalog-tile__drop-shadow-style .catalog-tile__content__footer__callouts,
.catalog-tile__no-image-style .catalog-tile__content__footer__callouts,
.catalog-tile__gray-outline-with-line-break-style .catalog-tile__content__footer__callouts {
  border-top: 0.125em solid #bbb;
  padding-top: 0.75em;
}

.catalog-tile__color-outline-style {
  border-color: v-bind(theme.buttonBackgroundColor);
}

.catalog-tile__color-outline-style:hover {
  border-color: v-bind(theme.tileBorderColor);
}
/* end tile styles  */
</style>
