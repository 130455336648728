import { v4 as uuidv4 } from 'uuid';
import HelperService from '@utils/helper-service';
import { DEFAULT_COLLAPSIBLE_SECTION_TOGGLE_COPY } from '@utils/constants';
import '@components/collapsible-section/collapsible-section-trigger';

const { createElement, replaceClass } = HelperService;

const hideClass = 'hide';

const attributes = {
  maxCharacters: 'max-characters',
  content: 'content',
  contentWrapperClass: 'content-wrapper-class',
  contentWrapperId: 'content-wrapper-id',
  secondaryContent: 'secondary-content',
  secondaryContentWrapperClass: 'secondary-content-wrapper-class',
  secondaryContentWrapperId: 'secondary-content-wrapper-id',
  sectionId: 'section-id',
  title: 'title',
  expandedTitle: 'expanded-title',
  checked: 'checked',
};

export default class InlineCollapsibleSection extends HTMLElement {
  #maxCharacters;

  #content;

  #secondaryContent;

  #collapsibleSectionTrigger;

  #contentWrapper;

  #secondaryContentWrapper;

  #sectionId;

  #title;

  #expandedTitle;

  #checked;

  #aboveTheFoldText;

  #hasDualContent;

  #initialized = false;

  static get observedAttributes() {
    return Object.values(attributes);
  }

  constructor() {
    super();

    this.#sectionId = this.getAttribute(attributes.sectionId) || uuidv4();
    this.#title = this.getAttribute(attributes.title) || DEFAULT_COLLAPSIBLE_SECTION_TOGGLE_COPY.DEFAULT;
    this.#expandedTitle =
      this.getAttribute(attributes.expandedTitle) || DEFAULT_COLLAPSIBLE_SECTION_TOGGLE_COPY.EXPANDED;
    this.#checked = this.hasAttribute(attributes.checked) && this.getAttribute(attributes.checked) !== 'false';
    this.#hasDualContent = !!this.getAttribute(attributes.secondaryContent);
  }

  connectedCallback() {
    if (this.#initialized) return;

    this.renderInlineCollapsibleSection();
    this.handleContent();

    this.#collapsibleSectionTrigger.addEventListener('toggle', this.handleToggle.bind(this));

    this.#initialized = true;
  }

  disconnectedCallback() {
    this.#collapsibleSectionTrigger.removeEventListener('toggle', this.handleToggle.bind(this));
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this.isConnected || !this.#collapsibleSectionTrigger?.isConnected) return;

    const contentAttrs = [attributes.content, attributes.maxCharacters, attributes.secondaryContent];

    if (contentAttrs.includes(name)) {
      this.#hasDualContent = !!this.getAttribute(attributes.secondaryContent);
      this.handleContent();
    }

    if (name === attributes.contentWrapperId) {
      this.#contentWrapper.setAttribute(name, newValue);
    }

    if (name === attributes.contentWrapperClass) {
      replaceClass(this.#secondaryContentWrapper, oldValue, newValue);
    }

    if (name === attributes.secondaryContentWrapperId && this.#hasDualContent) {
      this.#secondaryContentWrapper.setAttribute(name, newValue);
    }

    if (name === attributes.secondaryContentWrapperClass && this.#hasDualContent) {
      replaceClass(this.#secondaryContentWrapper, oldValue, newValue);
    }

    const triggerAttrs = [attributes.title, attributes.checked, attributes.sectionId, attributes.expandedTitle];
    if (triggerAttrs.includes(name)) {
      this.#collapsibleSectionTrigger.setAttribute(name, newValue);
    }

    if (name === attributes.sectionId) {
      this.#collapsibleSectionTrigger.setAttribute(attributes.sectionId, newValue);

      if (!this.getAttribute(attributes.contentWrapperId)) {
        this.#contentWrapper.setAttribute('id', `inline-collapsible-section-content-${newValue}`);
      }

      if (!this.getAttribute(attributes.secondaryContentWrapperId) && this.#hasDualContent) {
        this.#secondaryContentWrapper.setAttribute('id', `inline-collapsible-section-secondary-content-${newValue}`);
      }
    }
  }

  renderContentWrapper(name, attrContentWrapperClass, attrContentWrapperId, hide) {
    const contentWrapperClasses = [name];

    if (hide) {
      contentWrapperClasses.push(hideClass);
    }

    if (attrContentWrapperClass) {
      contentWrapperClasses.push(attrContentWrapperClass);
    }

    return createElement('div', {
      class: contentWrapperClasses.join(' '),
      id: attrContentWrapperId || `${name}-${this.#sectionId}`,
    });
  }

  renderInlineCollapsibleSection() {
    this.#contentWrapper = this.renderContentWrapper(
      'inline-collapsible-section-content',
      this.getAttribute(attributes.contentWrapperClass),
      this.getAttribute(attributes.contentWrapperId)
    );

    this.appendChild(this.#contentWrapper);

    if (this.getAttribute(attributes.secondaryContent)) {
      this.#secondaryContentWrapper = this.renderContentWrapper(
        'inline-collapsible-section-secondary-content',
        this.getAttribute(attributes.secondaryContentWrapperClass),
        this.getAttribute(attributes.secondaryContentWrapperId),
        true
      );

      this.appendChild(this.#secondaryContentWrapper);
    }

    this.#collapsibleSectionTrigger = createElement('sjwc-collapsible-section-trigger', {
      [attributes.sectionId]: this.#sectionId,
      [attributes.title]: this.#title,
      [attributes.checked]: this.#checked,
      [attributes.expandedTitle]: this.#expandedTitle,
    });

    this.appendChild(this.#collapsibleSectionTrigger);
  }

  handleToggle(e) {
    this.#checked = e.detail.checked;
    this.handleContent();
  }

  handleContent() {
    if (this.#hasDualContent) {
      this.handleDualContent();
    } else {
      this.handleSingularContent();
    }

    this.setContentSpacing();
  }

  handleSingularContent() {
    this.#content = this.getAttribute(attributes.content) || '';
    this.#maxCharacters = this.getAttribute(attributes.maxCharacters) || 200;

    const truncatedContent = this.#content.slice(0, this.#maxCharacters);
    let truncatedCopy = truncatedContent;

    if (truncatedContent.length < this.#content.length) truncatedCopy += '...';

    this.#aboveTheFoldText = !this.#checked ? truncatedCopy : this.#content;
    this.#collapsibleSectionTrigger.classList.toggle(hideClass, truncatedContent === this.#content);
    this.#contentWrapper.innerHTML = this.#aboveTheFoldText;
  }

  handleDualContent() {
    this.#content = this.getAttribute(attributes.content) || '';
    this.#maxCharacters = this.getAttribute(attributes.maxCharacters) || 200;
    this.#secondaryContent = this.getAttribute(attributes.secondaryContent) || '';

    const primaryContentAtMaxChar = this.#content.length >= this.#maxCharacters;
    const hidePrimaryContent = !this.#content;

    let truncatedContent = '';
    let truncatedCopy = '';

    if (hidePrimaryContent) {
      this.#contentWrapper.classList.add(hideClass);

      truncatedContent = this.#secondaryContent.slice(0, this.#maxCharacters);
      truncatedCopy = truncatedContent;

      if (this.#maxCharacters < this.#secondaryContent.length) truncatedCopy += '...';

      this.#aboveTheFoldText = !this.#checked ? truncatedCopy : this.#secondaryContent;
      this.#collapsibleSectionTrigger.classList.toggle(hideClass, truncatedContent === this.#secondaryContent);
      this.#secondaryContentWrapper.classList.remove(hideClass);
      this.#secondaryContentWrapper.innerHTML = this.#aboveTheFoldText;

      return;
    }

    if (primaryContentAtMaxChar) {
      truncatedContent = this.#content.slice(0, this.#maxCharacters);
      truncatedCopy = truncatedContent;

      if (truncatedContent.length < this.#content.length) truncatedCopy += '...';

      this.#aboveTheFoldText = !this.#checked ? truncatedCopy : this.#content;
      this.#collapsibleSectionTrigger.classList.toggle(hideClass, truncatedContent === this.#content);
      this.#secondaryContentWrapper.classList.toggle(hideClass, !this.#checked);
      this.#contentWrapper.innerHTML = this.#aboveTheFoldText;
      this.#secondaryContentWrapper.innerHTML = this.#secondaryContent;

      return;
    }

    if (!primaryContentAtMaxChar) {
      const remainingChars = this.#maxCharacters - this.#content.length;
      const addedSecondaryContent = this.#secondaryContent.slice(0, remainingChars);

      truncatedContent = `${addedSecondaryContent}...`;

      this.#aboveTheFoldText = !this.#checked ? truncatedContent : this.#secondaryContent;
      this.#secondaryContentWrapper.classList.remove(hideClass);
      this.#contentWrapper.innerHTML = this.#content;
      this.#secondaryContentWrapper.innerHTML = this.#aboveTheFoldText;
    }
  }

  setContentSpacing() {
    this.#contentWrapper.style.marginTop = '15px';
    this.#contentWrapper.style.marginBottom = '10px';

    if (this.getAttribute(attributes.secondaryContent)) {
      this.#secondaryContentWrapper.style.marginTop = '15px';
      this.#secondaryContentWrapper.style.marginBottom = '10px';
    }
  }
}

customElements.define('sjwc-inline-collapsible-section', InlineCollapsibleSection);
