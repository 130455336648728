import HelperService from '@utils/helper-service';

const attributes = {
  videoId: 'video-id',
  playDisallowed: 'play-disallowed',
};

export default class VimeoEmbed extends HTMLElement {
  #rootElement;

  #scriptElement;

  #playerElement;

  #videoId;

  #vimeoInstance;

  #playDisallowed;

  static get observedAttributes() {
    return Object.values(attributes);
  }

  connectedCallback() {
    this.#videoId = this.getAttribute(attributes.videoId);
    this.#playDisallowed =
      this.hasAttribute(attributes.playDisallowed) && this.getAttribute(attributes.playDisallowed) !== 'false';

    this.#rootElement = document.createElement('div');
    this.#rootElement.classList.add('embed-container');

    this.#playerElement = document.createElement('iframe');

    const playerAttributes = {
      src: this.buildSrcUrlFromVideoId(this.#videoId),
      width: 'WIDTH',
      height: 'HEIGHT',
      frameborder: '0',
      webkitallowfullscreen: '',
      mozallowfullscreen: '',
      allowfullscreen: '',
    };

    Object.keys(playerAttributes).forEach((attr) => this.#playerElement.setAttribute(attr, playerAttributes[attr]));

    this.#scriptElement = HelperService.loadScript(
      {
        src: 'https://player.vimeo.com/api/player.js',
      },
      this.#rootElement,
      this.instantiateInstance.bind(this)
    );

    this.#rootElement.appendChild(this.#playerElement);
    this.appendChild(this.#rootElement);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === attributes.playDisallowed && !!this.#vimeoInstance) {
      this.handlePlayDisallowed(newValue);
    }
  }

  buildSrcUrlFromVideoId(externalId) {
    // here's an example of an externalId:
    // 123456780
    // 123456780?
    // 123456780/?
    // 123456780/abcde12345
    // 123456780/abcde12345?
    const parts = externalId.split('/');
    const videoId = parts[0].split('?')[0];
    const securityHash = parts[1] ? parts[1].split('?')[0] : '';

    let srcUrl = `//player.vimeo.com/video/${videoId}/?portrait=0&title=0&byline=0&badge=0`;
    if (securityHash) {
      srcUrl = `//player.vimeo.com/video/${videoId}/?h=${securityHash}&portrait=0&title=0&byline=0&badge=0`;
    }
    return srcUrl;
  }

  instantiateInstance() {
    this.#vimeoInstance = new Vimeo.Player(this.#playerElement);

    this.handlePlayDisallowed(this.#playDisallowed);
  }

  async handlePlayDisallowed(newValue) {
    const falseValues = ['false', 'null', null, false, undefined];
    this.#playDisallowed = !falseValues.includes(newValue);

    const isPaused = await this.#vimeoInstance.getPaused();
    const playingWhenNotAllowed = this.#playDisallowed && !isPaused;

    this.classList.toggle('pe-none', this.#playDisallowed || false);

    if (playingWhenNotAllowed) {
      await this.#vimeoInstance.pause();
    }
  }
}

customElements.define('sjwc-vimeo', VimeoEmbed);
