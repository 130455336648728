export default class LoadingSpinner extends HTMLElement {
  connectedCallback() {
    const div = document.createElement('div');
    div.classList.add('sjwc-loading-spinner');

    const icon = document.createElement('i');
    icon.classList.add('fa', 'fa-spinner', 'fa-spin');

    div.appendChild(icon);
    this.appendChild(div);
  }
}

if (!customElements.get('sjwc-loading-spinner')) {
  customElements.define('sjwc-loading-spinner', LoadingSpinner);
}
